@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap");

*,
::after,
::before {
  box-sizing: border-box;
}

:root {
  --theme-color: #5c63c0;
}

::selection {
  background: #d6d6e6;
  color: #ff7373;
}

a {
  color: inherit;
  text-decoration: none;
}

hr {
  width: 100%;
}

.flex-1 {
  display: flex;
  flex: 1;
}

.d-n {
  display: none !important;
}

.d-f {
  display: flex !important;
}

.f-w {
  flex-wrap: wrap;
}

.fd-c {
  flex-direction: column;
}

.flex {
  flex: 1;
}

.a-i-c {
  align-items: center;
}

.small-fw {
  flex-wrap: wrap;
}

.text-center {
  text-align: center;
}

.theme-color {
  color: var(--theme-color) !important;
}

.brand-color {
  color: #ed1c24 !important;
}

.bg-brand {
  background-color: #ed1c24 !important;
}

.gap-15 {
  gap: 15px;
}

.badge {
  display: inline-block;
  font-size: 12px;
  padding: 4px 10px;
  background: rgb(228, 228, 228);
  border-radius: 15px !important;
  user-select: none;
  -webkit-user-select: none;
  margin-left: 0;
}

.badge.badge-success {
  background: hsl(120, 30%, 85%);
  color: hsl(120, 50%, 35%);
}

.badge.badge-danger {
  background: hsl(0, 25%, 90%);
  color: hsl(0, 62%, 41%);
}
.badge.badge-warning {
  background: #aed8545e;
  color: hsl(64, 88%, 38%);
}
.badge.badge-info {
  background: hsla(212, 60%, 84%, 0.926);
  color: hsl(234, 90%, 48%);
}

.theme-button {
  background: var(--theme-color);
  color: #fff !important;
  color: #fff;
  border: 0;
  outline: 0;
  padding: 10px 20px;
  font-size: 14px;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 5px;
  margin: 0;
  cursor: pointer;
  transition: 0.3s all ease;
  display: inline-flex;
}

.theme-button:hover {
  background: #454a93;
  transition: 0.3s all ease;
}

.bg-grey {
  background: #aaa !important;
}

img {
  user-select: none;
}

input:not([type="checkbox"], [type="radio"]),
select,
textarea {
  background: transparent;
  border: 0;
  outline: 0;
  padding: 10px 20px;
  width: 100%;
  font-size: 14px;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid transparent;
  border-radius: 5px !important;
  /* margin: 0 !important; */
}

select {
  appearance: auto;
  -webkit-appearance: auto;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
}

@-ms-viewport {
  width: device-width;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 300px;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background: #e8edf3;
  background-size: cover;
  -webkit-tap-highlight-color: red;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.text-center {
  text-align: center;
}

.nowrap {
  white-space: nowrap;
}

.close_icon {
  cursor: pointer;
  font-weight: bolder;
  background: #e1e1e1;
  width: 30px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  border-radius: 30px;
}

/*	Wrapper  */

.wrapper {
  display: flex;
  min-height: 100vh;
}

/*	Navigation  */

nav {
  flex: 1;
  max-width: 260px;
  height: 100vh;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  background: #fff;
  overflow: auto;
}

nav ul {
  margin-top: 30px;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

nav ul.submenu {
  margin-top: 12px;
  background: #f5f5f5;
  border-radius: 5px;
}

nav ul li ul.submenu {
  height: 0;
  overflow: hidden;

  &.show {
    height: auto;
  }
}

nav ul li a {
  display: inline-flex;
  width: 100%;
  padding: 12px;
  border-radius: 5px;
  color: rgb(33 37 41 / 60%);
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
}

nav ul li.active > a {
  background: #e8edf3;
  color: var(--theme-color);
}

nav ul li:not(.active) a:hover {
  color: var(--theme-color);
  transition: 0.3s color ease;
  cursor: pointer;
}

nav ul li.active ul.submenu li.active a {
  /* color: var(--theme-color); */
  color: var(--theme-color);
}

nav ul li.active:has(.submenu) > a {
  background: transparent;
  color: var(--theme-color);
}

nav .log-out {
  margin-top: auto;
  border-top: 1px solid rgb(33 37 41 / 05%);
  padding-top: 25px;
  align-content: center;
}

nav .log-out a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: rgb(33 37 41);
  text-decoration: none;
  font-size: 15px;
}

nav .log-out a:hover {
  color: #ed1c24;
  transition: 0.3s color ease;
  cursor: pointer;
}

nav .log-out a img {
  margin-right: 12px;
}

nav .close-button {
  display: none;
  cursor: pointer;
}

/*	Wrapper Body  */

.wrapper-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 40px;
  width: calc(100% - 300px);
}
.auth {
  margin-left: 0px;
}

.collapse-button {
  margin-right: 20px;
  display: none;
  cursor: pointer;
}

.collapse-button span {
  display: block;
  width: 16px;
  height: 2px;
  margin: 0;
  margin-bottom: 5px;
  background: #ed1c24;
  border-radius: 40px;
}

.collapse-button span:nth-child(3) {
  margin-bottom: 0;
}

.collapse-button span:nth-child(2) {
  width: 12px;
}

.page-heading {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 35px;
  justify-content: space-between;
}

.h1 {
  font-size: 21px;
  font-weight: 700;
  margin: 0;
}

.page-heading h1 {
  font-size: 21px;
  font-weight: 700;
  margin: 0;
}

.page-heading .total {
  margin-left: auto;
  padding: 10px 17px;
  background: #f5f7fa;
  border-radius: 50px;
  font-size: 13px;
  font-weight: 400;
  color: rgb(33 37 41 / 60%);
}

.wrapper-body .filters {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.wrapper-body .filters .search,
.wrapper-body .filters .country,
.wrapper-body .filters .job-post {
  flex: 1;
  background: #f5f7fa;
  display: flex;
  align-items: center;
  margin-right: 20px;
  border-radius: 5px;
  position: relative;
  flex-wrap: wrap;
}

.wrapper-body .filters form {
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
}

.wrapper-body .filters form button {
  padding: 10px 15px;
  border: 0;
  outline: 0;
  color: #ed1c24;
  background: transparent;
  font-size: 14px;
  cursor: pointer;
}

.wrapper-body .filters input:focus,
.wrapper-body .filters textarea:focus,
.wrapper-body .filters select:focus {
  border: 1px solid #ccbfbf;
}

.wrapper-body .filters .search img,
.wrapper-body .filters .country img,
.wrapper-body .filters .job-post img {
  position: absolute;
  right: 20px;
}
.search span {
  margin: 5px;
}

.wrapper-body .filters .actions {
  margin-left: auto;
}

.wrapper-body .filters .actions a {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  color: inherit;
  margin-left: 10px;
}

.wrapper-body .filters .actions a.bulk-del {
  margin-right: 20px;
  color: #e16368;
}

/*	table.custom  */

.wrapper-body .table-responsive {
  width: 100%;
  max-height: calc(100vh - 280px);
  background: #f5f7fa;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 0 1px 4px #d7e2ef;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  margin-bottom: 30px;
}

.wrapper-body table.custom {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto !important;
}

.wrapper-body table.custom thead {
  position: sticky;
  z-index: 1;
  top: 0;
}

.wrapper-body table.custom thead:before {
  content: "";
  width: 100%;
  height: 40px;
  background: #e4e9f1;
  position: absolute;
  z-index: -1;
  border-radius: 6px;
}

.wrapper-body table.custom thead:after {
  content: "";
  width: 100%;
  height: 40px;
  background: #f5f7fa;
  position: absolute;
  z-index: -2;
  top: -20px;
}

.wrapper-body table.custom th,
.wrapper-body table.custom td {
  padding: 12px 15px;
  font-size: 0.85rem;
}

.wrapper-body table.custom th {
  color: #8190a6;
  font-weight: 500;
  white-space: nowrap;
}

.wrapper-body table.custom tbody tr:first-child td {
  padding-top: 10px;
}

.wrapper-body table.custom .days span:first-child {
  padding-left: 0;
}

.wrapper-body table.custom .days span {
  color: #b9b9b9;
  font-weight: 500;
  padding: 5px;
}

.wrapper-body table.custom .days span.yes {
  color: var(--theme-color);
}

.wrapper-body table.custom td a {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: #4b76d7;
  cursor: pointer;
}
.wrapper-body table.custom td a:hover {
  color: blue;
  cursor: pointer;
}

.wrapper-body table.custom td a img {
  margin: 0 6px;
}

.wrapper-body .copyright {
  font-size: 12px;
  color: rgb(33 37 41 / 40%);
  text-align: right;
  margin-top: auto;
  padding-top: 30px;
}

/*	Subscribe  */

.cards-outer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.cards-outer table.subscribe-table {
  width: 100%;
}

.cards-outer table.subscribe-table tr {
  display: flex;
  flex-wrap: wrap;
}

.cards-outer table.subscribe-table tr td {
  background: #f5f7fa;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 0 1px 4px #d7e2ef;
  margin-bottom: 20px;
  margin-right: 20px;
  width: 100%;
  max-width: calc(100% / 4 - 15px);
  list-style: none;
}

.cards-outer table.subscribe-table tr td:nth-child(4n) {
  margin-right: 0;
}

.cards-outer table.subscribe-table tr td a {
  font-size: 14px;
  text-decoration: none;
  display: inline-flex;
  color: #366ff0;
  word-break: break-all;
}

.cards-outer table.subscribe-table tr td p {
  margin: 0;
  margin-top: 12px;
  font-size: 12px;
  font-weight: 500;
  color: rgb(33 37 41 / 40%);
}

/*	Log In  */

.wrapper.login_bg {
  position: relative;
}

.wrapper.login_bg:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url("./images/login_bg.png");
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.wrapper.bg .wrapper-body {
  padding: 30px;
}

.login {
  width: 100%;
  max-width: 500px;
  margin: 40px auto;
  background: #f5f7fa;
  padding: 40px 16px;
  border-radius: 6px;
  box-shadow: 0 1px 4px #d7e2ef;
}

.login form {
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
}

.login input:not(input[type="checkbox"]) {
  background: white;
  outline: 0;
  padding: 10px 15px;
  width: 100%;
  font-size: 14px;
  border: 1px solid #d5e0f0;
  border-radius: 5px;
  /* margin: 0; */
  margin-bottom: 10px;
  box-shadow: 0 1px 4px #d7e2ef;
}

.login button {
  display: block;
  box-shadow: 0 12px 30px -10px #8186d5;
  margin: 0 auto;
  margin-top: 40px;
  padding: 10px 50px;
}

.login button:hover {
  box-shadow: 0 15px 30px -4px #8186d5;
}

label.remember {
  margin-top: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: rgb(0 0 0 / 60%);
}
label.has-asterisk {
  position: relative;
}

label.has-asterisk:after {
  content: "*";
  color: red;
  position: absolute;
  top: -0.2em; /* Adjust as needed for positioning */
  left: 100%; /* Position it just after the label text */
  margin-left: 2px; /* Adjust as needed for spacing */
}

.login input:not(input[type="checkbox"]):focus {
  border: 1px solid #e77276;
}

.login .forgot-password {
  display: block;
  text-align: center;
  font-size: 14px;
  color: rgb(33 37 41 / 60%);
  margin-top: 60px;
  text-decoration: none;
}

.login .forgot-password:hover {
  color: rgb(33 37 41 / 100%);
}

.round-profile {
  border-radius: 50%;
  object-fit: cover;
}

/*	Cards  */

.rounded {
  border-radius: 6px;
}

.card {
  width: 100%;
  background: #f5f7fa;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 0 1px 4px #d7e2ef;
  margin-bottom: 30px;
}

.card .card {
  background: #e8edf3;
  box-shadow: none;
  margin-bottom: 0;
}

.card .heading {
  font-size: 15px;
  margin: 0;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  font-weight: 500;
  color: var(--theme-color);
}

.card .heading:has(+ p) {
  margin-bottom: 0;
  padding-bottom: 5px;
  border-bottom: 0px solid #ccc;
}

.card .heading + p {
  padding-bottom: 10px;
  color: gray;
  border-bottom: 1px solid #ccc;
  font-size: 14px;
}

.card select {
  padding: 10px;
  border: 1px solid #888;
  margin-right: 15px;
  -webkit-appearance: auto;
  appearance: auto;
}

.card select:focus {
  border: 2px solid #000;
}

.card .small-message {
  font-size: 13px;
  font-weight: 500;
  color: #888;
}

.card .details {
  padding-top: 20px;
}

.card .details p {
  font-size: 13px;
  margin: 7px 20px;
  margin-left: 0;
}

.card .inputs:not(:last-child) {
  margin-bottom: 15px;
}

.card .inputs .input-group {
  flex: 1;
  display: block;
}
.input-group label.has-asterisk {
  position: relative;
}

.input-group label.has-asterisk:after {
  content: "*";
  color: red;
  position: absolute;
  top: -0.2em; /* Adjust as needed for positioning */
  left: 100%; /* Position it just after the label text */
  margin-left: 2px; /* Adjust as needed for spacing */
}

.card .inputs .input-group > label {
  font-size: 14px;
  margin-bottom: 10px;
  /* display: block; */
  font-weight: 500;
}

.card .inputs .input-group > label.question {
  padding: 10px 0;
  font-size: 16px;
}

.card .inputs .input-group > label.question:has(+ .label-brief) {
  margin-bottom: 0;
}

.card .inputs .input-group input,
.card .inputs .input-group textarea {
  border: 1px solid #888;
}

.card .inputs .input-group input:focus,
.card .inputs .input-group textarea:focus {
  border: 2px solid #000;
}

.input {
  border: 1px solid #888;
}

.input:focus {
  border: 2px solid #000;
}

.card .inputs .input-group .label-brief {
  margin-top: 0;
  margin-bottom: 20px !important;
  color: rgb(108, 108, 140);
  font-weight: 500;
  font-size: 13px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.card .inputs .input-group .label-brief small {
  background: rgb(229, 229, 238);
  padding: 4px 10px;
  border-radius: 5px;
  user-select: none;
  -webkit-user-select: none;
}

.card table {
  width: 100%;
  border-collapse: collapse;
}

.card table tr:first-child td {
  border-top: 0;
}

.card table tr td {
  border-top: 1px solid #ccc;
  vertical-align: top;
}

.custom-checkbox {
  display: grid;
  gap: 15px 30px;
  grid-template-columns: repeat(auto-fit, minmax(min(10rem, 100%), 1fr));
}

.custom-checkbox.small {
  grid-template-columns: repeat(auto-fit, minmax(min(50px, 100%), 1fr));
}

.custom-checkbox > div {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  cursor: pointer;
}

.custom-checkbox > div > * {
  cursor: pointer;
}

.custom-checkbox > div > input {
  margin-top: 13px;
  transform: scale(1.18);
}

.custom-checkbox > div > label {
  padding: 10px 0;
  flex: 1;
  font-size: 13px;
}

.add-more-list {
  margin-top: 40px;
  padding: 20px 0;
}

.generated_invoice {
  margin-bottom: 50px;
}

.generated_invoice iframe {
  height: 60vh;
  border-radius: 15px;
  border: 1px solid #ccc;
}

.label {
  font-size: 15px;
  margin-bottom: 8px;
  font-weight: 500;
  color: var(--theme-color);
}

.actions-wrapper {
  display: flex;
  justify-content: flex-end;
}

.action-bar {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-bottom: 20px;
  width: 100%;
  gap: 20px;
}

.filters-wrapper {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: flex-end; */
  flex: 1;
  gap: 20px;
}

.filter {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.select {
  background: #f5f7fa;
  align-items: center;
  border-radius: 5px;
}

.select:focus {
  border: 1px solid #cdcdcd;
}

.actions-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

/*************************************************************/
/*	Resonsive Styling 	*/
/*************************************************************/

@media only screen and (max-width: 1399px) {
  nav {
    padding: 30px;
    overflow-y: auto;
  }

  .wrapper-body {
    padding: 30px;
  }

  .wrapper-body .page-heading {
    margin-bottom: 30px;
  }

  .rto-management-actions-wrapper {
    width: 100%;
  }

  .cards-outer table.subscribe-table tr td {
    max-width: calc(100% / 3 - 15px);
    margin-right: 15px;
    margin-bottom: 15px;
  }

  .cards-outer table.subscribe-table tr td:nth-child(4n) {
    margin-right: 15px;
  }

  .cards-outer table.subscribe-table tr td:nth-child(3n) {
    margin-right: 0;
  }
}

@media only screen and (max-width: 1199px) {
  nav {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background: white;
    transform: translateX(-100%);
    transition: 0.3s all ease;
    width: 260px;
  }

  .wrapper.show-menu nav {
    transform: translateX(0%);
    transition: 0.3s all ease;
  }

  .wrapper.show-menu nav .close-button {
    position: absolute;
    right: 25px;
    transform: translate(50%, -10%);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 0 30px 30px 0;
  }

  .wrapper.show-menu nav .close-button span {
    width: 14px;
    height: 1.5px;
    background: #ed1c24;
    display: block;
    transform: rotate(45deg);
    position: absolute;
  }

  .wrapper.show-menu nav .close-button span:last-child {
    transform: rotate(-45deg);
  }

  .collapse-button {
    display: block;
  }

  .wrapper-body {
    padding-left: 30px;
    position: relative;
  }

  .wrapper.show-menu .wrapper-body:before {
    content: "";
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgb(0 0 0 / 60%);
    z-index: 1;
    transition: 0.3s all ease;
  }

  /* .wrapper-body .filters .search,
  .wrapper-body .filters .country,
  .wrapper-body .filters .job-post {
    width: calc(100% / 4 - 20px);
    margin-bottom: 0px;
  } */

  /* .filter {
    min-width: calc(100% / 2);
    margin-bottom: 20px;
  }

  .actions-wrapper {
    width: 100%;
  } */

  .actions-wrapper {
    width: 100%;
  }

  .wrapper-body .copyright {
    text-align: center;
  }
}

@media only screen and (max-width: 992px) {
  .wrapper-body .filters form {
    flex: 100%;
  }

  .filter {
    min-width: 100%;
  }

  /* .wrapper-body .filters .search,
  .wrapper-body .filters .country,
  .wrapper-body .filters .job-post {
    width: calc(100% / 2 - 20px);
    margin-bottom: 20px;
    flex: 100%;
    max-width: 100% !important;
  } */

  .wrapper-body .filters .actions {
    margin-right: auto;
  }
}

@media only screen and (max-width: 767px) {
  .wrapper-body .filters .search,
  .wrapper-body .filters .country,
  .wrapper-body .filters .job-post {
    width: calc(100% / 2 - 20px);
    margin-bottom: 20px;
    flex: 100%;
    max-width: 100% !important;
  }

  .card .inputs .input-group {
    min-width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .wrapper-body .filters .search,
  .wrapper-body .filters .country,
  .wrapper-body .filters .job-post {
    width: calc(100%);
    margin-bottom: 10px;
    margin-right: 0;
  }

  .wrapper-body .filters .actions {
    margin-top: 10px;
  }

  .wrapper-body .page-heading h1 {
    font-size: 17px;
  }

  .wrapper-body .page-heading .total {
    padding: 6px 10px;
    font-size: 11px;
    font-weight: 500;
  }

  .wrapper-body .filters input,
  .wrapper-body .filters select {
    font-size: 13px;
    padding: 10px 15px;
  }

  .wrapper-body .filters .actions a {
    font-size: 12px;
  }

  .wrapper-body table.custom,
  .wrapper-body table.custom tbody,
  .wrapper-body table.custom tr,
  .wrapper-body table.custom td {
    display: block;
  }

  .wrapper-body .table-responsive {
    max-height: inherit;
    background: transparent;
    padding: 0;
    box-shadow: none;
  }

  .wrapper-body table.custom thead {
    display: none;
  }

  .wrapper-body table.custom tbody tr {
    background: #f5f7fa;
    padding: 16px;
    border-radius: 6px;
    box-shadow: 0 1px 4px #d7e2ef;
    margin-bottom: 10px;
    position: relative;
  }

  .wrapper-body table.custom tbody tr td {
    text-align: left;
    padding: 6px 10px !important;
  }

  .wrapper-body table.custom.resume tbody tr td:before {
    content: attr(data-title);
    margin-right: 20px;
    font-size: 12px;
    display: inline-flex;
    width: 60px;
    color: rgb(33 37 41 / 60%);
  }

  .wrapper-body table.custom tbody tr td:nth-child(1) {
    display: none;
  }

  .wrapper-body table.custom tbody tr td:nth-child(2) {
    background: #e5edf3;
    border-radius: 5px;
    margin-bottom: 8px;
    color: #3b414b;
  }

  .wrapper-body table.custom.resume th,
  .wrapper-body table.custom.resume td:not(:nth-child(6)) {
    white-space: normal;
  }

  .wrapper-body table.custom.resume tbody tr td:last-child {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .wrapper-body table.custom.resume tbody tr td:last-child:before,
  .wrapper-body table.custom tbody tr td:nth-child(2):before {
    content: none;
  }

  .cards-outer table.subscribe-table tr td {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 8px;
  }

  .cards-outer table.subscribe-table tr td:nth-child(4n) {
    margin-right: 0;
  }

  table#description_table tr,
  table#description_table tr td {
    display: block;
  }

  .card table tr:first-child td,
  .card table tr td {
    padding: 0;
    border-top: 0;
  }

  table#description_table tr {
    border-top: 1px solid #ccc;
    padding: 40px 0;
  }

  table#description_table tr:first-child {
    border-top: 0;
    padding-top: 0;
  }
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  margin: 0;
}

@media only screen and (min-width: 768px) {
  .mb-md-15 {
    margin-bottom: 15px;
  }

  .small-fw {
    flex-wrap: nowrap;
  }
}

ul.tab-nav {
  margin: 0;
  padding: 1rem;
  list-style: none;
  background: #d5dde7;
  border-radius: 5px;
  position: sticky;
  top: 30px;
}

ul.tab-nav li {
  position: relative;
  display: flex;
  align-items: center;
}

ul.tab-nav li::before {
  content: "";
  display: inline-flex;
  width: 8px;
  aspect-ratio: 1/1;
  background: #f5f7fa;
  border-radius: 20px;
  margin: 0 15px;
}

ul.tab-nav li a {
  flex: 1;
  padding: 10px 0;
}

ul.tab-nav li::after {
  content: "";
  height: 100%;
  width: 2px;
  position: absolute;
  left: 16px;
  background: white;
  transform: translateX(100%);
}

ul.tab-nav li:first-child::after {
  height: 50%;
  bottom: 0;
}

ul.tab-nav li:last-child::after {
  height: 50%;
  top: 0;
}

ul.tab-nav li.complete::before,
ul.tab-nav li.complete::after {
  background: var(--theme-color);
}

ul.tab-nav li.active::before,
ul.tab-nav li.active::after {
  box-shadow: inset 0px 34px 0px #abb1ff;
}

ul.tab-nav li.active a {
  color: var(--theme-color);
  font-weight: 500;
}

/* Org Chart CSS Start */
/* 
* {
  padding: 0;
  margin: 0;
}
.header {
  height: 45px;
  line-height: 45px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  display: block;
  margin: 0;
  width: 100%;
  min-width: 1024px;
  height: 48px;
  max-height: 44px;
  background: rgba(221, 221, 221, 0.92);
  font-size: 17px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 60px;
}
.follow {
  float: right;
  margin-right: 79px;
}
.follow img {
  width: 25px;
  position: relative;
  top: 7px;
}

body {
  font-family: "Sen", sans-serif;
  background: #f9f9fa;
}

.container {
  width: 90%;
  margin: 7% auto;
}

.follow a {
  color: #131313;
  text-decoration: none;
}

.org-tree ul {
  padding-top: 20px;
  position: relative;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.org-tree li {
  float: left;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 20px 5px 0 5px;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

/*We will use ::before and ::after to draw the connectors*/
/* 
.org-tree li::before,
.org-tree li::after {
  content: "";
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 1px solid #ccc;
  width: 50%;
  height: 20px;
}
.org-tree li::after {
  right: auto;
  left: 50%;
  border-left: 1px solid #ccc;
}

.org-tree li:only-child::after,
.org-tree li:only-child::before {
  display: none;
}

.org-tree li:only-child {
  padding-top: 0;
}

.org-tree li:first-child::before,
.org-tree li:last-child::after {
  border: 0 none;
}

.org-tree li:last-child::before {
  border-right: 1px solid #ccc;
  border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
}
.org-tree li:first-child::after {
  border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
}

.org-tree ul ul::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 1px solid #ccc;
  width: 0;
  height: 20px;
}

.org-tree li a {
  border: 1px solid #ccc;
  padding: 5px 10px;
  text-decoration: none;
  color: #666;
  font-family: arial, verdana, tahoma;
  font-size: 11px;
  display: inline-block;

  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.org-tree li a:hover,
.org-tree li a:hover + ul li a {
  background: #c8e4f8;
  color: #000;
  border: 1px solid #94a0b4;
}

.org-tree li a:hover + ul li::after,
.org-tree li a:hover + ul li::before,
.org-tree li a:hover + ul::before,
.org-tree li a:hover + ul ul::before {
  border-color: #94a0b4;
}

.card {
  position: relative;
  padding-top: 50px;
  width: 300px;
}

.card .image {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -10px;
  z-index: 100;
}
.card .image img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  border: 13px solid #96f6b7;
}
.card .card-body {
  background: #fff;
  height: 56px;
  text-align: center;
  width: 300px;
  padding-top: 60px;
  border-radius: 16px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  position: relative;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
}

.card .card-body h2 {
  font-weight: 600;
  line-height: 20px;
}
.card .card-body p {
  line-height: 20px;
  font-size: small;
}
.card .card-footer {
  padding: 3px;
  background: #96f6b7;
  text-align: center;
  bottom: 0;
  position: relative;
  width: 295px;
  border-radius: 16px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
}
.card .card-footer img {
  width: 25px;
  margin: 5px;
} */

/* Org Chart CSS End */

.disabled {
  opacity: 0.7;
  pointer-events: none;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(189, 187, 187, 0.511);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.overlay-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it appears above other content */
}

.loader-container-nav {
  text-align: center;
  background-color: white; /* Loader container background color */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add a shadow for the loader container */
}

.punchin--box_outer {
  grid-template-columns: 1fr 1fr;
}

.punchin--box {
  max-width: 250px;
}

.punchin--box_outer > p {
  margin-bottom: 0;
  font-size: 13px;
}

.punchin--box_outer > p span {
  font-weight: bold;
  color: gray;
  text-transform: uppercase;
}

.error-text {
  border: 2px solid #ce1f25 !important;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.8;
  color: #212529;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  /* border: 1px solid #ced4da; */
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.user-type-tag {
  position: absolute;
  right: 0;
  top: 0;
}
.user-type-tag .badge {
  display: inline-block;
  font-size: 12px;
  padding: 8px;
  background: rgb(228, 228, 228);
  border-radius: 0px 5px !important;
  user-select: none;
  -webkit-user-select: none;
  margin-left: 0;
}

/* ASIDE START */

aside {
  --aside-width: 300px;
}
aside.right-sidebar {
  flex-basis: var(--aside-width);
  background: white;
  padding: 40px 30px;
  position: relative;
  z-index: 99;
}

aside.right-sidebar:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100vw;
  width: 100vw;
  height: 100%;
  background: rgb(0 0 0 / 70%);
  z-index: -1;
}

aside.right-sidebar ul.track-progress {
  list-style: none;
  position: relative;
  padding: 0;
}

aside.right-sidebar ul.track-progress::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 8px;
  height: 100%;
  background: #e8e8e8;
}

aside.right-sidebar ul.track-progress li {
  position: relative;
  padding-bottom: 20px;
  padding-left: 2rem;
}

aside.right-sidebar ul.track-progress li h6 {
  margin-bottom: 2px;
}

aside.right-sidebar ul.track-progress li h6 + p + * {
  border-top: 1px solid #e0e0e0;
  padding-top: 8px;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}

aside.right-sidebar ul.track-progress li:after,
aside.right-sidebar ul.track-progress li:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(calc(-50% + 4px));
}
aside.right-sidebar ul.track-progress li:before {
  width: 20px;
  aspect-ratio: 1;
  background: white;
  border-radius: 50px;
  border: 5px solid #e8e8e8;
  z-index: 1;
}

aside.right-sidebar ul.track-progress li:after {
  background: #e8e8e8;
  width: 4px;
  height: 100%;
  z-index: 0;
}

aside.right-sidebar ul li.approved:before {
  background: #3abe3a;
  border-color: #bbe8bb;
}

aside.right-sidebar ul li.approved:after {
  background: linear-gradient(#bbe8bb, transparent);
}

aside.right-sidebar ul li.rejected:before {
  background: #be3a3a;
  border-color: #e8bbbb;
}

aside.right-sidebar ul li.rejected:after {
  background: linear-gradient(#e8bbbb, transparent);
}

aside.right-sidebar ul li.in-progress:before {
  background: #bbd9e8;
  border-color: #bbd9e8;
  border-width: 0px;
  animation: ripple 1s ease-in-out 0s infinite forwards reverse;
}

aside.right-sidebar ul li.in-progress:after {
  background: linear-gradient(#bbd9e8, transparent);
}

@keyframes ripple {
  to {
    border-width: 10px;
    background: #3a96be;
  }
}

aside.right-sidebar ul li p {
  margin: 0;
  padding-bottom: 8px;
}

aside.right-sidebar .detail {
  border-bottom: 1px solid #e4e4e4;
  border-top: 1px solid #e4e4e4;
  margin-bottom: 40px;
  padding: 10px 0;
}

aside.right-sidebar .detail h5 {
  margin-bottom: 5px;
  font-size: 15px;
}

aside.right-sidebar .detail h6 {
  margin: 0;
  font-size: 12px;
}

aside.right-sidebar ul li:last-child {
  padding-bottom: 0;
}

/* ASIDE END */

.react-datepicker-wrapper {
  width: 100%;
}

.modal-full-height {
  height: 100vh;
  margin: 0;
}

.modal-right {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 400px;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.min-dashboard-card {
  min-height: 189px;
}

.space-text {
  margin: 36px;
}

.cursor-link {
  cursor: pointer;
}

.password-container {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 400px;
  width: 100%;
  margin: 20px auto;
}

.password-action {
  position: absolute;
  right: 0;
  top: 18px;
  transform: translateY(-50%);
  cursor: pointer;
}

.password-action img {
  width: 20px;
  height: 20px;
  margin: 0 10px;
}

.color-blue-grey {
  color: #6d757d;
}

.breadcrumbs-separator {
  color: #bbbbbb;
  font-size: 12px;
  margin-inline: 5px;
}

/* Add media queries to make it responsive */
@media (max-width: 768px) {
  .password-action {
    right: 5px;
  }
}

@media (max-width: 480px) {
  .password-action {
    right: 2px;
  }
}

.marquee-container {
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  width: 100%; /* Set this to your desired width */
  background-color: red;
  color: white;
  font-size: 16px;
  font-weight: bolder;
  margin-bottom: 10px;
}

.marquee {
  display: inline-block;
  animation: marquee 30s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
