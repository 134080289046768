* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 300px;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background: #e8edf3;
  background-size: cover;
  -webkit-tap-highlight-color: red;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.centered-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 200px);
}

.emp-card {
  .card {
    &:hover {
      background-color: #f3ecec;
      cursor: pointer;
    }
  }
}

.asset-list-card {
  .card {
    &:hover {
      background-color: #9da1d669;
      cursor: pointer;
    }
  }
}
.submenu-list {
  display: none;
  list-style: none;
}

.show-submenu .submenu-list {
  display: block;
}

.collapse.show {
  visibility: visible;
  height: auto;
  transition: visibility 0.3s ease;
}

/* Start of OrgChart */
/* 
.App-chart {
  padding: 0;
  margin: 0;
}
.App-chart .container {
  width: 100%;
  margin: 7% auto;
}

.org-tree ul {
  padding-top: 20px;
  position: relative;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.org-tree li {
  float: left;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 20px 5px 0 5px;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
} */

/*We will use ::before and ::after to draw the connectors*/

/*.org-tree li::before,
.org-tree li::after {
  content: "";
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 1px solid #ccc;
  width: 50%;
  height: 20px;
}
.org-tree li::after {
  right: auto;
  left: 50%;
  border-left: 1px solid #ccc;
}

.org-tree li:only-child::after,
.org-tree li:only-child::before {
  display: none;
}

.org-tree li:only-child {
  padding-top: 0;
}

.org-tree li:first-child::before,
.org-tree li:last-child::after {
  border: 0 none;
}

.org-tree li:last-child::before {
  border-right: 1px solid #ccc;
  border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
}
.org-tree li:first-child::after {
  border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
}

.org-tree ul ul::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 1px solid #ccc;
  width: 0;
  height: 20px;
}

.org-tree li a {
  border: 1px solid #ccc;
  padding: 5px 10px;
  text-decoration: none;
  color: #666;
  font-family: arial, verdana, tahoma;
  font-size: 11px;
  display: inline-block;

  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.org-tree li a:hover,
.org-tree li a:hover + ul li a {
  background: #c8e4f8;
  color: #000;
  border: 1px solid #94a0b4;
}

.org-tree li a:hover + ul li::after,
.org-tree li a:hover + ul li::before,
.org-tree li a:hover + ul::before,
.org-tree li a:hover + ul ul::before {
  border-color: #94a0b4;
}

.App-chart .card {
  position: relative;
  padding-top: 50px;
  width: 300px;
}
.App-chart .card .image {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -10px;
  z-index: 100;
}
.App-chart .card .image img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  border: 13px solid #96f6b7;
}
.App-chart .card .card-body {
  background: #fff;
  height: 56px;
  text-align: center;
  width: 300px;
  padding-top: 60px;
  border-radius: 16px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  position: relative;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
}

.App-chart .card .card-body h2 {
  font-weight: 600;
  line-height: 20px;
}
.App-chart .card .card-body p {
  line-height: 20px;
  font-size: small;
}
.App-chart .card .card-footer {
  padding: 3px;
  background: #96f6b7;
  text-align: center;
  bottom: 0;
  position: relative;
  width: 295px;
  border-radius: 16px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
}
.App-chart .card .card-footer img {
  width: 25px;
  margin: 5px;
}
*/

.custom_calendar {
  background-color: white;
  .calendar_nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 20px;
    .heading {
      font-size: 24px;
      margin-top: 15px;
      padding-bottom: 10px;
      font-weight: 500;
      color: var(--theme-color);
    }
    span {
      font-size: 35px;
      font-weight: 500;
      color: #979ab9;
      cursor: pointer;
    }
    .disabled-span {
      color: #979ab9;
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
  table {
    width: 100%;
    table-layout: fixed;
    --padding: 10px;
    border-collapse: collapse;

    th {
      text-align: left;
      padding: var(--padding);
    }
    td {
      padding: var(--padding);
      border: 3px solid #7b7c7d8f;
      vertical-align: top;
      /* background: rgba(243, 245, 243, 0.563); */
      span {
        --dimension: 30px;
        --bg: rgb(222, 222, 222);
        display: block;
        width: var(--dimension);
        aspect-ratio: 1/1;
        line-height: var(--dimension);
        margin-inline-start: auto;
        border-radius: 50px;
        background: var(--bg);
        text-align: center;
        margin-bottom: 20px;
      }
      p {
        margin: 8px 0;
        font-size: 0.8em;
      }

      &.positive {
        background: rgb(213, 238, 213);
        span {
          --bg: rgb(181, 214, 181);
        }
      }
      &.negative {
        background: #eed5d5;
        span {
          --bg: rgb(214, 181, 181);
        }
      }
      &.holiday {
        background: #e8ed5abd;
        span {
          --bg: rgb(194, 205, 163);
        }
      }
      &.leave {
        background: #73b5d3b5;
        span {
          --bg: rgb(181, 195, 200);
        }
      }
      &.weekoff {
        background: #d9cfcfa6;
        opacity: 0.8;
      }
    }
  }
}

.indexedStep {
  color: rgb(190, 190, 190);
  width: 30px;
  height: 30px;
  font-size: 12px;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(206, 206, 206);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.indexedStep.accomplished {
  background-color: #664de5;
  color: white;
  border-style: none;
}

.RSPBprogressBar {
  height: 2px;
  width: 30%;
  line-height: 1;
  border-radius: 10px;
  position: relative;
  background-color: rgb(207, 207, 207);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 0;
  margin: 20px auto;
}

.RSPBprogressBar .RSPBstep {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  position: absolute;
  transform: translateX(-50%);
  transition-property: all;
  transition-timing-function: ease;
}

.RSPBprogressBar .RSPBprogressBarText {
  color: white;
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.RSPBprogressBar .RSPBprogression {
  position: absolute;
  transition: width 0.3s ease;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 10px;
  background: #664de5;
  z-index: -1;
}

@media screen and (max-width: 480px) {
  .indexedStep {
    width: 15px;
    height: 15px;
    font-size: 6px;
  }
}

.page {
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

#form-file-upload {
  height: 16rem;
  width: 28rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
  color: #727880;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  font-size: 1rem;
  border: none;
  font-family: "Oswald", sans-serif;
  background-color: rgba(13, 110, 253, 0.25);
  color: #463f71;
}

.upload-button:hover {
  color: #2e227d;
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
#uploaded-documents {
  list-style: none;
  padding: 0;
  margin-top: 30px;
}

#uploaded-documents li {
  border: 1px solid #ddd;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
}

#uploaded-documents li span {
  margin-left: 100px !important;
  cursor: pointer;
  color: darkred;
  font-weight: 500;
}

.policy-uploaded-file {
  position: relative; /* Set position to relative */
  display: inline-block;
  /* Display as inline-block to fit contents */
  border-radius: 5px;
  width: 100%;
  line-height: normal;
}
.span-policy {
  max-width: 30px !important;
  word-wrap: break-word;
}

/* Styling the 'X' button */
.policy-file-delete {
  position: absolute;
  top: -42px;
  right: -42px;
  cursor: pointer;
  border: #989899 solid 1px;
  border-radius: 12px;
  padding: 2px 5px;
  background-color: rgb(223, 220, 220);
}

.policy-file-delete:hover {
  background-color: #aba4a4;
}

.truncate-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.label-h {
  font-weight: 700;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #f0f0f0;
  margin: auto;
}

.react-datepicker__calendar-icon {
  padding: 15px 7px !important;
  float: right !important;
  font-size: 12px !important;
}

/* .css-b62m3t-container {
  z-index: 2 !important;
} */
.search {
  z-index: 2;
}

.css-1nmdiq5-menu {
  z-index: 2 !important;
}
